// v-money-input.js
import MoneyInput from './MoneyInput.vue'
import MoneyFilter from './MoneyFilter.js'

export default {
  install(Vue) {
    Vue.component('v-money-input', MoneyInput)
    Vue.filter('money', MoneyFilter)
  },
}
