<template>
  <div class="nj-app-bar">
    <div class="app-bar-logo">
      <img :src="logo" alt="logo" />
    </div>
    <div class="app-bar-title">
      {{ title }}
    </div>
    <div class="app-bar-user">
      <v-btn icon @click="$store.dispatch('checkin/logout')">
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CheckinLayoutAppBar',
  props: {
    logo: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },

  methods: {
    avatarSrc() {
      return '#'
    },
  },
}
</script>
<style scoped>
.nj-app-bar {
  margin-bottom: 3rem;
  margin-top: 1rem;
  padding: 0px 1rem;
  height: 40px;
  display: flex;
  align-items: center;
}

.app-bar-logo {
  /* flex: 0 0 140px; */
}

.app-bar-logo img {
  max-height: 45px;
  max-width: 100%;
}

.app-bar-title {
  font-size: 1.625rem;
  color: #384048 !important;
  flex: 1;
}
/* .app-bar-user >>> .v-list-item:hover:before {
  border-radius: 1rem;
} */
</style>
