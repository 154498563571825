import router from '../router'
import Api from '@/resources/NajaSocialApi'

const getDefaultState = () => {
  return {
    editable_fields: {
      checkin_covenant_editable: null,
      checkin_cpf_editable: null,
      checkin_name_editable: null,
      checkin_plan_editable: null,
    },
    required_fields: {},
    schedules: [],
    hits: {
      sex: [],
      civil: [],
      breed: [],
      attachments: [],
    },
    configs: {
      redirect_url: null,
      final_text: null,
    },
    form: {
      id: null,
      step: 0,
      schedule: null,
      covenant: {},
      termsAccepted: false,
      patient: {
        code: null,
        name: null,
        birthday: null,
        gender: null,
        civil_status: null,
        breed: null,
        cpf: null,
        rg: null,
        cep: null,
        address: null,
        address_number: null,
        neighborhood: null,
        complement: null,
        city_ibge_code: null,
        city: null,
        doc_front: null,
        doc_back: null,
        phone_ddd: null,
        phone_number: null,
        phone_ddd_two: null,
        phone_number_two: null,
        email: null,
      },
    },
  }
}

const data = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    resetState: state => {
      Object.assign(state, getDefaultState())
    },

    updateForm: (state, form) => {
      state.form = Object.assign(state.form, form)
    },

    updateConfigs: (state, configs) => {
      state.configs = Object.assign(state.configs, configs)
    },

    updateSchedules: (state, schedules) => {
      state.schedules = schedules
    },

    updateHits: (state, hits) => {
      state.hits = Object.assign(state.hits, hits)
    },

    updateRequiredFields: (state, fields) => {
      state.required_fields = Object.assign(state.required_fields, fields)
    },

    updateEditableFields: (state, fields) => {
      state.editable_fields = Object.assign(state.editable_fields, fields)
    },
  },

  actions: {
    logout(context) {
      context.commit('resetState')
      context.commit('clearUser', null, { root: true })

      router.push('/checkin', () => {})
    },

    async loadSchedules(context, params) {
      const response = await Api.preserviceSchedules(params)

      if (response.status == 200) {
        let schedules = response.data.schedules.filter(schedule => {
          let date = this._vm.$moment(schedule.AEX_Data, 'DD/MM/YYYY')
          return date.isSameOrAfter(new Date(), 'day')
        })

        schedules = _.orderBy(
          schedules,
          schedule => {
            return this._vm.$moment(schedule.AEX_Data, 'DD/MM/YYYY')
          },
          ['desc']
        )

        if (schedules.length) {
          let patient = schedules[0].Paciente
          let hits = response.data.hits
          let configs = response.data.configs

          context.commit('updateSchedules', schedules)
          context.commit('updateHits', hits)
          context.commit('updateConfigs', configs)

          let checkinData = {
            patient: {
              code: patient.pac_cod,
              name: patient.pac_nome,
              birthday: patient.pac_datanasc,
              gender: patient.Sexo.sxo_isn,
              civil_status: patient.EstadoCivil.etc_isn,
              breed: patient.pac_racacor,
              cpf: patient.pac_cpf,
              rg: patient.pac_rg,
              cep: patient.pac_cep,
              address: patient.pac_endereco,
              address_number: patient.pac_endnum,
              neighborhood: patient.pac_bairro,
              complement: patient.pac_endcpl,
              city_ibge_code: patient.Cidade.cdd_codibge,
              city:
                patient.Cidade.cdd_nome != null
                  ? `${patient.Cidade.cdd_nome} - ${patient.Cidade.cdd_ufd_cod}`
                  : null,
              email: patient.pac_email,
              doc_front: null,
              doc_back: null,
              phone_number: patient.fonecontato1,
              phone_number_two: patient.fonecontato2,
              // phone_ddd:
              //   patient.fonecontato1 == null
              //     ? null
              //     : patient.fonecontato1.substring(0, 2),
              // phone_number:
              //   patient.fonecontato1 == null
              //     ? null
              //     : patient.fonecontato1.substring(2),
              // phone_ddd_two:
              //   patient.fonecontato2 == null
              //     ? null
              //     : patient.fonecontato2.substring(0, 2),
              // phone_number_two:
              //   patient.fonecontato2 == null
              //     ? null
              //     : patient.fonecontato2.substring(2),
            },
          }

          context.commit('updateForm', checkinData)
          context.commit('updateRequiredFields', response.data.required_fields)
          context.commit('updateEditableFields', response.data.editable_fields)
        }
      }
    },
  },

  getters: {
    selectedSchedule: state => {
      return (
        state.schedules.find(s => {
          return s.AEX_Isn == state.form.schedule
        }) || {}
      )
    },

    selectedCovenant: (state, getters) => {
      return getters.selectedSchedule.Convenio || {}
    },

    rules: state => {
      const rules = {}
      Object.entries(state.required_fields).forEach(entry => {
        const [key, value] = entry
        if (value) {
          rules[key] = [v => !!v || 'Obrigatório']
        }
      })
      return rules
    },
  },
}

export default data
