import Vue from 'vue'
import Router from 'vue-router'
import store from './store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('./views/home'),
      meta: { hideMenu: true, title: 'Home' },
      beforeEnter: (to, from, next) => {
        if (store.getters.role === 'checkin') {
          next('/checkin')
        } else if (store.state.data.user.role != 'patient') {
          next('/exams')
        } else {
          next()
        }
      },
    },
    {
      path: '/schedule',
      name: 'schedule',
      meta: { layout: 'schedule', public: true, title: 'Agendamento Online' },
      component: () => import('./views/schedule'),
    },
    {
      path: '/print',
      name: 'print',
      meta: { layout: 'schedule', public: true, title: 'Print test' },
      component: () => import('./views/print'),
    },
    // {
    //   path: '/test',
    //   name: 'test',
    //   component: () => import('./views/test'),
    // },
    {
      path: '/exams',
      name: 'exams',
      meta: { title: 'Meus Exames' },
      component: () => import('./views/exams'),
    },
    {
      path: '/preferences',
      name: 'preferences',
      meta: { title: 'Preferências' },
      component: () => import('./views/preferences'),
    },
    {
      path: '/login',
      name: 'login',
      meta: { layout: 'basic', public: true },
      component: () => import('./views/login'),
    },
    {
      path: '/r/:token',
      name: 'password_change',
      meta: { layout: 'basic', public: true },
      component: () => import('./views/change_password'),
    },
    {
      path: '/checkin',
      name: 'checkin',
      meta: { layout: 'checkin', public: true, title: 'Check-in Online' },
      component: () => import('./views/checkin'),
    },
    {
      path: '/c/:token',
      name: 'business_card',
      meta: { layout: 'basic', public: true },
      component: () => import('./views/BusinessCard.vue'),
    },
    {
      path: '/m/:token',
      name: 'public_medical_report',
      meta: { layout: 'basic', public: true },
      component: () => import('./views/public_medical_report'),
    },
    {
      path: '/o/:token',
      name: 'public_order',
      meta: { layout: 'basic', public: true },
      component: () => import('./views/public_order'),
    },
    {
      path: '/v/:token',
      name: 'virtual_schedule',
      meta: { layout: 'basic', public: true },
      component: () => import('./views/VirtualSchedule.vue'),
    },
    {
      path: '/q/:token',
      name: 'questionnaires',
      meta: { layout: 'questionnaires', public: true },
      component: () => import('./views/questionnaires'),
    },
    {
      path: '/agendamentos/:token/questionarios',
      name: 'schedule_questionnarires',
      meta: { layout: 'questionnaires', public: true },
      component: () => import('./views/schedule_questionnaires'),
    },
    {
      path: '/p/:token',
      name: 'preparations',
      meta: { layout: 'questionnaires', public: true },
      component: () => import('./views/preparations'),
    },
    {
      path: '/quote/:token',
      name: 'quote',
      meta: { layout: 'schedule', public: true },
      component: () => import('./views/quote'),
    },
    {
      path: '/medical_reports/:id',
      name: 'medical_reports',
      meta: { layout: 'basic' },
      component: () => import('./views/MedicalReport.vue'),
    },
    {
      path: '/medical_reports_combined',
      name: 'medical_reports_combined',
      meta: { layout: 'basic' },
      component: () => import('./views/MedicalReportCombined.vue'),
    },
    {
      path: '/api-error',
      name: 'apiErrorPage',
      component: () => import('./views/ApiErrorPage.vue'),
    },
    {
      path: '/404',
      name: 'notFoundErrorPage',
      component: () => import('./views/NotFoundPage.vue'),
    },
    {
      path: '/:slug',
      name: 'slug',
      meta: { layout: 'schedule', public: true, title: 'Agendamento Online' },
      component: () => import('./views/schedule'),
    },
  ],
})

function nextPage(to, next) {
  let publicPages = [
    'login',
    'test',
    'apiErrorPage',
    'notFoundErrorPage',
    'checkin',
    'business_card',
    'virtual_schedule',
    'schedule',
    'password_change',
    'slug',
    'public_medical_report',
    'public_order',
    'questionnaires',
    'schedule_questionnarires',
    'print',
    'quote',
    'preparations',
  ]

  if (publicPages.includes(to.name) || store.getters.isLogged) {
    next()
  } else {
    router.push({ name: 'login' })
  }
}

router.beforeEach((to, from, next) => {
  document.title = store.state.data.organization.name
    ? `Portal Naja | ${store.state.data.organization.name}`
    : 'Portal Naja'
  if (store.getters.isOrganizationLoaded) {
    nextPage(to, next)
  } else if (to.name != 'apiErrorPage') {
    store.dispatch('getOrganization').then(() => {
      nextPage(to, next)
    })
  } else {
    next()
  }
})

export default router
