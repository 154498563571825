<template>
  <v-app
    id="app"
    :data-theme="theme"
    data-sidebar="close"
    v-cloak
    :style="cssProps"
  >
    <BasicLayout v-if="layout == 'basic'">
      <router-view></router-view>
    </BasicLayout>
    <ScheduleLayout
      v-if="layout == 'schedule'"
      :organization="organization"
      :user="user"
      :page-title="$route.meta.title || $route.name"
      @logout="$store.dispatch('logout')"
    >
      <router-view :key="$route.fullPath"></router-view>
    </ScheduleLayout>
    <CheckinLayout
      v-if="layout == 'checkin'"
      :organization="organization"
      :page-title="$route.meta.title || $route.name"
      @logout="$store.dispatch('logout')"
    >
      <router-view :key="$route.fullPath"></router-view>
    </CheckinLayout>
    <QuestionnairesLayout
      v-if="layout == 'questionnaires'"
      :organization="organization"
      :page-title="$route.meta.title || $route.name"
      @logout="$store.dispatch('logout')"
    >
      <router-view :key="$route.fullPath"></router-view>
    </QuestionnairesLayout>
    <MainLayout
      v-if="layout == 'default'"
      :organization="organization"
      :user="user"
      :page-title="$route.meta.title || $route.name"
      @logout="$store.dispatch('logout')"
    >
      <router-view :key="$route.fullPath"></router-view>
    </MainLayout>
  </v-app>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import TermsModal from '@/components/TermsModal.vue'
import { themes } from '@/plugins/vuetify.js'
import BasicLayout from '@/layouts/basic'
import MainLayout from '@/layouts/main'
import ScheduleLayout from '@/layouts/schedule'
import CheckinLayout from '@/layouts/checkin'
import QuestionnairesLayout from '@/layouts/questionnaires'

export default {
  name: 'App',
  components: {
    TermsModal,
    BasicLayout,
    MainLayout,
    ScheduleLayout,
    CheckinLayout,
    QuestionnairesLayout,
  },
  computed: {
    ...mapGetters({
      theme: 'theme',
    }),

    ...mapState({
      organization: state => state.data.organization,
      user: state => state.data.user,
    }),
  },

  data() {
    return {
      layout: 'default',
      cssProps: {},
    }
  },

  watch: {
    theme() {
      this.setTheme()
    },

    $route() {
      // set layout
      this.layout = this.$route.meta.layout || 'default'

      // terms
      // const requiresTerms =
      //   this.$store.state.data.organization.termos_portal.id != null
      // if (requiresTerms) {
      //   if (this.$store.getters.isLogged) {
      //     const terms = this.$store.state.data.organization.termos_portal.id
      //     if (!this.$store.state.data.user.termos_uso_aceitos.includes(terms)) {
      //       this.$refs.termsModal.open()
      //     }
      //   }
      // }
    },
  },

  // life
  // beforeCreate() {
  //   console.log('beforeCreate', this.$route.meta.layout)
  // },

  created() {
    // console.log('create', this.$route.meta.layout)
    this.layout = this.$route.meta.layout || 'default'
    this.setTheme()
  },

  // beforeMount() {
  //   console.log('beforeMount', this.$route.meta.layout)
  // },
  //
  // mounted() {
  //   console.log('mounted', this.$route.meta.layout)
  // },
  //
  // beforeUpdate() {
  //   console.log('beforeUpdate', this.$route.meta.layout)
  // },
  //
  // updated() {
  //   console.log('updated', this.$route.meta.layout)
  // },

  methods: {
    setTheme() {
      const mergedTheme = {
        ...this.$vuetify.theme.themes.light,
        ...themes[this.theme],
      }
      this.$vuetify.theme.themes.light = mergedTheme

      // set theme vars
      const themeColors = {}
      Object.keys(this.$vuetify.theme.themes.light).forEach(color => {
        themeColors[`--v-${color}`] = this.$vuetify.theme.themes.light[color]
      })
      this.cssProps = themeColors
    },
  },
}
</script>
<style lang="scss">
$font-family: 'Montserrat', sans-serif;
#app .v-application {
  [class*='text-'] {
    font-family: $font-family, sans-serif !important;
  }
  font-family: $font-family, sans-serif !important;
}
</style>
