<template>
  <div class="checkin-layout fill-height">
    <SignInLayout v-if="step == 0">
      <slot></slot>
    </SignInLayout>
    <v-app v-else class="default-layout">
      <div style="display: flex; width: 100%">
        <v-main class="root-container">
          <AppBar
            :logo="organization.logo"
            :title="pageTitle"
            class="primary--text"
          >
            <template
              v-for="(index, name) in $scopedSlots"
              v-slot:[name]="data"
            >
              <slot :name="name" v-bind="data"></slot>
            </template>
          </AppBar>
          <div :class="$vuetify.breakpoint.smAndDown ? 'mx-4' : 'mx-8'">
            <div class="steps px-8" v-show="step != 0 && step != 5">
              <v-row align="center" justify="center">
                <v-col class="fill-height">
                  <span class="font-weight-medium primary--text">
                    {{ stepTitle }}
                  </span>
                  <br />
                  <span class="font-weight-medium"> Passo {{ step }}/4 </span>
                </v-col>
                <v-col>
                  <Breadcrumbs />
                </v-col>
              </v-row>
            </div>
            <div class="checkin-content px-8 mt-6 mb-4">
              <slot></slot>
            </div>
          </div>
        </v-main>
      </div>
    </v-app>
  </div>
</template>
<script type="text/javascript">
import { mapState } from 'vuex'
import AppBar from './app_bar'
import Breadcrumbs from './breadcrumbs'
import SignInLayout from '@/layouts/signin'
export default {
  name: 'CheckinLayout',
  components: { AppBar, Breadcrumbs, SignInLayout },
  props: {
    pageTitle: {
      type: String,
      default: '',
    },
    menuItems: {
      type: Array,
      default: () => [],
    },
    menuDark: {
      type: Boolean,
      default: false,
    },
    menuColor: {
      type: String,
      default: null,
    },
    organization: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapState({
      step: state => state.checkin.form.step,
    }),

    stepTitle() {
      return {
        1: 'Insira seus dados',
        2: 'Selecione os exames',
        3: 'Selecione o Convênio e Plano',
        4: 'Confirmar dados',
      }[this.step]
    },
  },
}
</script>
<style scoped lang="scss">
/* .root-container {
  padding: 32px !important;
} */
.checkin-layout > .v-application {
  background: #f2f3fb;
}

.checkin-layout >>> .nj-app-bar {
  padding-right: 1rem;
  padding-left: 1rem;
  background: white;
  height: 60px;
  margin-bottom: 1.5rem;
}

.checkin-layout >>> .app-bar-title {
  font-size: 1.125rem;
  color: var(--primary-color);
}

.checkin-layout .steps,
.checkin-layout .checkin-content {
  padding: 1rem;
  background: white;
  border-radius: 0.5rem;
}

.checkin-layout .checkin-content {
  min-height: calc(100vh - 220px);
}
</style>
<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  // .root-container {
  //   padding: 25px 17px !important;
  //   margin-top: 50px;
  // }
}
</style>
