import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import pt from 'vuetify/lib/locale/pt'

Vue.use(Vuetify)

export const themes = {
  red: {
    primary: '#80171C',
  },

  green: {
    primary: '#61BB95',
  },

  blue: {
    primary: '#438EB9',
  },

  yellow: {
    primary: '#FCB425',
  },
}

export default new Vuetify({
  options: {
    customProperties: true,
  },
  lang: {
    locales: { pt },
    current: 'pt',
  },
  theme: {
    themes: {
      light: {
        primary: '#F58221',
        secondary: '#787878',
        bold: '#384048',
      },
    },
  },
})
