<template>
  <div class="questionnaires-layout">
    <AppBar :logo="organization.logo" :title="pageTitle"></AppBar>
    <div class="questionnaires-content">
      <slot></slot>
    </div>
  </div>
</template>
<script type="text/javascript">
import { mapState } from 'vuex'
import AppBar from './app_bar'
import Breadcrumbs from './breadcrumbs'
import SignInLayout from '@/layouts/signin'
export default {
  name: 'CheckinLayout',
  components: { AppBar, Breadcrumbs, SignInLayout },
  props: {
    pageTitle: {
      type: String,
      default: '',
    },
    menuItems: {
      type: Array,
      default: () => [],
    },
    menuDark: {
      type: Boolean,
      default: false,
    },
    menuColor: {
      type: String,
      default: null,
    },
    organization: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapState({
      step: state => state.checkin.form.step,
    }),

    stepTitle() {
      return {
        1: 'Insira seus dados',
        2: 'Selecione os exames',
        3: 'Selecione o Convênio e Plano',
        4: 'Confirmar dados',
      }[this.step]
    },
  },
}
</script>
<style scoped lang="scss">
.questionnaires-layout {
  background: #f4f4f8;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.questionnaires-content {
  background-color: white;
  // overflow-y: auto;
  width: 100%;
  height: 100%;
  border-radius: 1.5rem 1.5rem 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  // overflow: hidden;
}
</style>
<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  // .root-container {
  //   padding: 25px 17px !important;
  //   margin-top: 50px;
  // }
}
</style>
