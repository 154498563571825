<template>
  <div class="basic-layout fill-height">
    <v-app>
      <v-main>
        <v-container class="pa-0 fill-height" fluid>
          <slot></slot>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>
<script type="text/javascript">
export default {
  name: 'BasicLayout',
}
</script>
