<template>
  <div class="nj-app-bar">
    <img :src="logo" alt="logo" />
  </div>
</template>
<script>
export default {
  name: 'ScheduleLayoutAppBar',
  props: {
    logo: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    user: {
      type: Object,
      default: () => {},
    },
  },

  methods: {
    avatarSrc() {
      return '#'
    },
  },
}
</script>
<style lang="scss" scoped>
.nj-app-bar {
  height: 100px;
  display: flex;
  align-items: center;
  margin-left: 1rem;

  justify-content: left;

  img {
    max-height: 45px;
  }
}

@import '~vuetify/src/styles/settings/_variables';
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .nj-app-bar {
    justify-content: center;
  }
}
</style>
