import Api from '../resources/NajaSocialApi'
import moment from 'moment'
import _ from 'lodash'
import $ from 'jquery'
import OnlineScheduleStateEnum from '@/enumerations/online_schedule_state_enum'

moment.locale('pt-BR')

const schedule = {
  namespaced: true,
  state: {
    loadingSchedules: true,
    options: {
      exam_schedule_enabled: null,
      exam_schedule_multiple_allowed: 'false',
      exam_schedule_confirmation_screen_tip: null,
      exam_schedule_date_screen_tip: null,
      exam_schedule_patient_screen_tip: null,
      exam_schedule_procedure_screen_tip: null,
      exam_schedule_done_screen_tip: null,
      exam_schedule_limit_days: null,
      exam_schedule_user_code: '',
      exam_schedule_shifts: [],
      exam_schedule_fato_gerador: null,
      exam_schedule_covenant_restrictions: [],
      exam_schedule_advanced_search: false,

      appointment_schedule_enabled: null,
      appointment_schedule_procedure_screen_tip: null,
      appointment_schedule_date_screen_tip: null,
      appointment_schedule_patient_screen_tip: null,
      appointment_schedule_confirmation_screen_tip: null,
      appointment_schedule_done_screen_tip: null,
      appointment_schedule_resources: null,
      appointment_schedule_do_not_consider_hours_restrictions: false,
      appointment_schedule_fato_gerador: null,
      appointment_schedule_covenant_restrictions: [],
      appointment_schedule_advanced_search: false,
    },
    step: 1,
    group: null,
    places: [],
    branches: [],
    examResouces: [],
    onlineSchedule: {
      id: null,
      status: null,
      kind: null,
    },
    products: {
      exams: [],
      appointments: [],
    },
    covenants: {
      exams: [],
      appointments: [],
    },
    doctorInfo: {
      CRM: null,
      CodigoNaja: null,
      NOME: null,
    },
    form: {
      exam: {
        covenant_id: null,
        plan_id: null,
        products: [],
        appointment: null,
        group: null,
        doctorParam: null,
        doctor_id: null,
      },
      date: {
        place_id: null,
        kind: 'date',
        places: [],
        date: null,
        hour_id: null,
        shifts: [],
      },
      patient: {
        logged: null,
        name: null,
        cpf: null,
        birthday: null,
        email: null,
        phone: null,
      },
      obs: '',
    },
  },

  actions: {
    async getScheduleResources(context) {
      let products = { appointments: [] }
      const scheduleResources =
        context.state.options.appointment_schedule_resources || []

      if (scheduleResources.length == 0) {
        context.commit('products', products)
        return
      }

      const result = await Api.scheduleResources($.param({ tipoAgenda: 1 }))
      const data = result.data
      products.appointments = data
        .filter(x => scheduleResources.includes(x.CodRecurso))
        .sort((a, b) => a['NomeRecurso'].localeCompare(b['NomeRecurso']))
      context.commit('products', products)
    },

    async createOnlineSchedule(context) {
      const payload = {
        online_schedule: {
          status: OnlineScheduleStateEnum.INITIALIZED,
          kind:
            context.state.group == null || context.state.group == 'exams'
              ? 'exam'
              : 'appointment',
        },
      }

      const result = await Api.onlineSchedules.save(null, payload)
      context.commit('onlineSchedule', result.data.online_schedule)
    },

    async onlineScheduleToFinished(context) {
      const payload = {
        online_schedule: {
          status: OnlineScheduleStateEnum.FINISHED,
        },
      }

      const result = await Api.onlineSchedules.save(
        context.state.onlineSchedule.id,
        payload
      )
      context.commit('onlineSchedule', result.data.online_schedule)
    },

    async getExamScheduleResources(context) {
      const result = await Api.scheduleResources($.param({ tipoAgenda: 2 }))
      context.commit('examResouces', result.data)
    },

    async getProducts(context) {
      let products = { exams: [], appointments: [] }
      const subgroups = context.state.options.exam_schedule_subgroups || []

      if (subgroups.length == 0) {
        context.commit('products', products)
        return
      }

      const result = await Api.products(subgroups.join())
      const data = result.data.products

      products.exams = data
        .map(product => {
          return {
            value: product.pro_cod,
            text: product.pro_nome,
            resource_value: product.subgrupo.sgr_codcomposto,
            resource_text: product.subgrupo.sgr_nome,
          }
        })
        .sort((a, b) => a['text'].localeCompare(b['text']))

      context.commit('products', products)
    },

    async getBranches(context) {
      let resources = []
      let selected_products = []
      if (context.state.form.exam.group == 'exams') {
        resources = context.getters.selectedProducts.map(x => x.resource_value)
        selected_products = context.getters.selectedProducts.map(x => x.value)
      }

      const params = {
        plc_isn: context.state.form.exam.plan_id,
        con_cod: context.state.form.exam.covenant_id,
        emp_icagendaonline: 'true',
      }

      if (resources.length > 0) {
        params.sgr_codcomposto = resources.join()
        params.selected_products = selected_products.join()
      }

      let result = await Api.branches($.param(params))
      context.commit(
        'branches',
        result.data.branches.sort((a, b) =>
          a['emp_nomfan'].localeCompare(b['emp_nomfan'])
        )
      )
    },

    async getCovenants(context, query = {}) {
      query.con_ativo = true
      query.cve_icagendaonline = true
      const result = await Api.covenants($.param(query))
      const covenants = result.data.covenants
      covenants.appointments.sort((a, b) =>
        a['con_nomefant'].localeCompare(b['con_nomefant'])
      )
      covenants.exams.sort((a, b) =>
        a['con_nomefant'].localeCompare(b['con_nomefant'])
      )
      context.commit('covenants', covenants)
    },

    async getOptions(context) {
      let result = await Api.scheduleOptions()
      context.commit('options', result.data.options)
    },

    async recursiveGetSchedules(context, date) {
      const monthLimit = 6
      let startDate = date || this._vm.$moment()

      context.commit('loadingSchedules', true)
      for (let monthIndex = 0; monthIndex < monthLimit; monthIndex++) {
        if (monthIndex > 0) {
          startDate = this._vm.$moment(startDate).add(1, 'M').startOf('month')
        }

        const endDate = this._vm.$moment(startDate).endOf('month')
        await context.dispatch('getSchedules', startDate, endDate)

        if (context.getters.hours.length > 0) {
          break
        }
      }
      context.commit('loadingSchedules', false)
    },

    async getSchedulesByMonth(context, month) {
      let startDate = this._vm.$moment(`${month}-01`, 'YYYY-MM-DD')

      const today = this._vm.$moment()

      if (startDate.isBefore(today, 'month')) {
        return
      }

      if (startDate.isSame(today, 'month')) {
        startDate = today
      }

      const endDate = this._vm.$moment(startDate).endOf('month')

      context.commit('loadingSchedules', true)
      await context.dispatch('getSchedules', startDate, endDate)
      context.commit('loadingSchedules', false)
    },

    async getSchedules(context, startDate = null, endDate = null) {
      startDate = startDate || this._vm.$moment()
      endDate = endDate || this._vm.$moment(startDate).endOf('month')

      const examResouces = context.state.examResouces.map(x => x.CodRecurso)
      const selectedExamResouces = context.state.options.exam_schedule_resources
      const resourcesExclude = _.xor(examResouces, selectedExamResouces).join(
        ','
      )

      const notConsiderHoursRestrictions =
        context.state.options
          .appointment_schedule_do_not_consider_hours_restrictions

      const payload = {
        'start-date': startDate.format('YYYYMMDD'), // YYYYMMDD,
        'end-date': endDate.format('YYYYMMDD'), // YYYYMMDD,
        online: true,
        group: context.state.group,
        covenant: context.state.form.exam.covenant_id,
        plan: context.state.form.exam.plan_id,
        excluir_recursos: resourcesExclude,
        nao_considerar_restricao_convenio: notConsiderHoursRestrictions,
      }

      if (context.state.form.exam.doctor_id) {
        payload.med_cod = context.state.form.exam.doctor_id
      }

      if (context.state.group == 'exams') {
        payload.product = (context.getters.selectedProducts[0] || {}).value
        payload.selected_products = context.getters.selectedProducts
          .map(x => x.value)
          .join()
      }

      if (context.state.group == 'appointments') {
        payload.resource = (
          context.getters.selectedAppointment || {}
        ).CodRecurso
        payload.product =
          context.getters.selectedAppointment.ProcedimentoDefault.Codigo
      }

      let result = await Api.schedules($.param(payload))
      let places = []

      result.data.schedules.forEach(place => {
        let newPlace = {
          id: place.emp_cod,
          name: place.emp_nomfan,
          address: place.emp_end,
          date: null,
          schedules: [],
        }

        place.medicos.forEach(doctor => {
          let newSchedule = {
            doctor: {
              id: doctor.med_cod,
              name: doctor.med_nome,
              hours: [],
            },
          }
          doctor.recursos.forEach(product => {
            product.agendas.forEach(schedule => {
              schedule.horarios.forEach(hour => {
                let day = moment(schedule.agd_data, 'YYYY-MM-DD').format(
                  'YYYYMMDD'
                )
                // remove it
                // day = moment(schedule.agd_data, 'YYYY-MM-DD').add(1, 'M').format('YYYYMMDD')
                let hourFormated = moment(hour.agi_horaseq, 'HHmm').format(
                  'HH:mm'
                )
                newSchedule.doctor.hours.push({
                  id: hour.agi_isn,
                  time: `${day} ${hourFormated}`,
                  // resource: `${product.rag_grp_cod}${product.rag_sgr_cod}`,
                  rag_cod: product.rag_cod,
                  rag_nome: product.rag_nome,
                  agi_isn: hour.agi_isn,
                })
              })
            })
          })
          newPlace.schedules.push(newSchedule)
        })

        places.push(newPlace)
      })

      context.commit('places', places)
    },
  },

  mutations: {
    step(state, step) {
      state.step = step
      this._vm.$scrollTop()
    },

    loadingSchedules(state, loading) {
      state.loadingSchedules = loading
    },

    doctorInfo: (state, data) => {
      state.doctorInfo = Object.assign(state.doctorInfo, data)
    },

    form: (state, data) => {
      state.form = Object.assign(state.form, data)
    },

    group: (state, data) => {
      state.group = data
    },

    examForm: (state, data) => {
      state.form.exam = Object.assign(state.form.exam, data)
    },

    dateForm: (state, data) => {
      state.form.date = _.clone(data)
    },

    places: (state, data) => {
      state.places = _.clone(data)
    },

    branches: (state, data) => {
      state.branches = _.clone(data)
    },

    products: (state, products) => {
      state.products = Object.assign(state.products, products)
    },

    examResouces: (state, examResouces) => {
      state.examResouces = Object.assign(state.examResouces, examResouces)
    },

    onlineSchedule: (state, onlineSchedule) => {
      state.onlineSchedule = Object.assign(state.onlineSchedule, onlineSchedule)
    },

    covenants: (state, covenants) => {
      state.covenants = Object.assign(state.covenants, covenants)
    },

    options: (state, options) => {
      state.options = Object.assign(state.options, options)
    },
  },

  getters: {
    manyProductsAllowed: state => {
      return state.form.exam.products.length > 1
    },

    summary: (state, getters) => {
      const covenants = state.covenants[state.group] || []
      const covenant =
        state.form.exam.covenant_id == null
          ? null
          : covenants.find(x => x.con_cod === state.form.exam.covenant_id)

      const plan =
        state.form.exam.plan_id && covenant
          ? covenant.planos.find(x => x.plc_isn === state.form.exam.plan_id)
          : null

      const exams = state.products.exams
        .filter(p => state.form.exam.products.includes(p.value))
        .map(x => x.text)

      const daysOfWeek = state.form.date.shifts
        .map(x => {
          const day = x.split('-')
          return `${day[0]}-${day[1]}`
        })
        .join(', ')

      const shifts = state.form.date.shifts.map(x => x.split('-')[2]).join(', ')

      const places = []
      state.form.date.places.forEach(place_id => {
        const branch = state.branches.find(x => x.emp_cod == place_id)
        const address = branch.emp_end
        const place_value = address
          ? `${branch.emp_nomfan} - ${address}`
          : branch.emp_nomfan
        places.push(place_value)
      })

      // const places = state.places.map(p => `${p.name} - ${p.address}`).join(',')

      const appointment = state.form.exam.appointment
        ? state.products.appointments.find(
            a => a.CodRecurso == state.form.exam.appointment
          )
        : null

      let doctor =
        appointment && state.form.exam.doctor_id != null
          ? appointment.Medicos.find(
              x => x.CodigoNaja == state.form.exam.doctor_id
            )
          : null

      const selectedHour =
        state.form.date.hour_id == null
          ? null
          : getters.hours.find(h => h.id === state.form.date.hour_id)

      const selectedPlace = selectedHour
        ? state.places.find(x => x.id == selectedHour.place_id)
        : null

      const place = selectedPlace
        ? `${selectedPlace.name} - ${selectedPlace.address}`
        : null

      const hour = selectedHour
        ? moment(selectedHour.time, 'YYYYMMDD HH:mm').format('HH:mm')
        : null

      const date = selectedHour
        ? moment(selectedHour.time, 'YYYYMMDD HH:mm').format('DD/MM/YYYY')
        : null

      if (doctor === null && selectedHour !== null && appointment !== null) {
        doctor = appointment.Medicos.find(
          x => x.CodigoNaja == selectedHour.doctor_id
        )
      }

      return {
        covenant: covenant?.con_nomefant,
        appointment: appointment?.NomeRecurso,
        plan: plan?.plc_nome,
        exams: exams,
        place: place,
        doctor: doctor?.NOME,
        hour: hour,
        date: date,
        daysOfWeek: daysOfWeek,
        shifts: shifts,
        places: places,
      }
    },

    groupLabel: state => {
      return {
        exams: 'Exame',
        appointments: 'Consulta',
      }[state.form.group]
    },

    selectedProducts: state => {
      return state.products.exams.filter(p =>
        state.form.exam.products.includes(p.value)
      )
    },

    selectedAppointment: state => {
      let appointment = state.products.appointments.filter(
        x => x.CodRecurso == state.form.exam.appointment
      )[0]
      if (appointment) {
        let resources = []
        appointment.GrupoProcedimento.forEach(group => {
          group.SubgrupoProcedimento.forEach(subgroup => {
            resources.push(`${group.CodigoGrupo}${subgroup.CodigoSub}`)
          })
        })

        appointment.resources = resources
      }

      return appointment
    },

    selectedBranches: state => {
      return state.branches.filter(p =>
        state.form.date.places.includes(p.emp_cod)
      )
    },

    selectedShifts: state => {
      let shifts = []
      state.form.date.shifts.forEach(shift => {
        const split = shift.split('-')
        const day = `${split[0]}-${split[1]}`
        const shiftName = split[2]

        shifts[day] = shifts[day] || []
        if (shifts[day].indexOf(shiftName) < 0) {
          shifts[day].push(shiftName)
        }
      })

      return shifts
    },

    covenant: state => {
      const covenants = state.covenants[state.group] || []
      return state.form.exam.covenant_id == null
        ? {}
        : covenants.find(x => x.con_cod === state.form.exam.covenant_id)
    },

    plan: (state, getters) => {
      return state.form.exam.plan_id == null
        ? {}
        : getters.covenant.planos.find(
            x => x.plc_isn === state.form.exam.plan_id
          )
    },

    hour: (state, getters) => {
      return state.form.date.hour_id == null
        ? {}
        : getters.hours.find(h => h.id === state.form.date.hour_id)
    },

    doctor: (state, getters) => {
      return _.isEmpty(getters.hour) ? null : getters.hour.doctor()
    },

    places: state => {
      return state.places.map(p => {
        return { id: p.id, name: p.name, address: p.address }
      })
    },

    schedules: state => {
      let schedules = []
      state.places.forEach(place => {
        place.schedules.forEach(schedule => {
          schedule.place_id = place.id
          schedules.push(schedule)
        })
      })

      return schedules
    },

    doctors: (state, getters) => {
      let doctors = []
      state.places.forEach(place => {
        place.schedules.forEach(schedule => {
          if (doctors.findIndex(d => d.id == schedule.doctor.id) < 0) {
            let doctor = {
              id: schedule.doctor.id,
              name: schedule.doctor.name,
              place_id: place.id,
              place: () => getters.places.find(x => x.id === place.id),
            }
            doctors.push(doctor)
          }
        })
      })

      return doctors
    },

    hours: (state, getters) => {
      let hours = []
      state.places.forEach(place => {
        place.schedules.forEach(schedule => {
          let doctor = schedule.doctor
          doctor.hours.forEach(hour => {
            if (hours.findIndex(h => h.id === hour.id) < 0) {
              let newHour = {
                id: hour.id,
                time: hour.time,
                doctor_id: doctor.id,
                place_id: place.id,
                schedule_id: schedule.id,
                resource: hour.resource,
                rag_cod: hour.rag_cod,
                rag_nome: hour.rag_nome,
                agi_isn: hour.agi_isn,
                doctor: () => getters.doctors.find(x => x.id === doctor.id),
                place: () => getters.places.find(x => x.id === place.id),
              }
              hours.push(newHour)
            }
          })
        })
      })

      return hours
    },
  },
}

export default schedule
