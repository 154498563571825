<template>
  <div class="breadcrumbs">
    <div :class="['item', { active: step == 1 }]">
      <v-btn class="disable-events" small fab elevation="0">
        <v-icon> mdi-account </v-icon>
      </v-btn>
      <span>Paciente</span>
    </div>
    <div :class="['item', { active: step == 2 }]">
      <v-btn class="disable-events" small fab dark elevation="0">
        <v-icon> mdi-heart-pulse </v-icon>
      </v-btn>
      <span>Exames</span>
    </div>
    <div :class="['item', { active: step == 3 }]">
      <v-btn class="disable-events" small fab dark elevation="0">
        <v-icon>mdi-hospital</v-icon>
      </v-btn>
      <span>Convênios</span>
    </div>
    <div :class="['item', { active: step == 4 }]">
      <v-btn class="disable-events" small fab dark elevation="0">
        <v-icon> mdi-check </v-icon>
      </v-btn>
      <span>Confirmar</span>
    </div>
  </div>
</template>
<script type="text/javascript">
import { mapState } from 'vuex'
export default {
  name: 'Breadcrumbs',
  computed: {
    ...mapState({
      step: state => state.checkin.form.step,
    }),
  },
}
</script>
<style scoped>
.breadcrumbs {
  display: flex;
  justify-content: end;
}

.breadcrumbs .item {
  margin-right: 2rem;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  color: #727173;
}

.breadcrumbs .item.active {
  color: var(--v-primary);
}

.breadcrumbs .item span {
  font-size: 0.625rem;
}

.breadcrumbs .item:last-child {
  margin-right: 0px;
}

.breadcrumbs >>> .v-btn {
  background-color: #e0e0e0 !important;
}

.breadcrumbs >>> .v-btn .v-icon {
  color: #727173 !important;
}

.breadcrumbs .item.active >>> .v-btn {
  background-color: var(--v-primary) !important;
  color: var(--primary-color);
}

.breadcrumbs .item.active >>> .v-btn .v-icon {
  color: white !important;
}
</style>
<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .breadcrumbs {
    justify-content: center;
  }
}
</style>
