// import { orderBy } from 'lodash'

// const data = {
//   rules: [
//     { key: 'INITIALIZED', value: 1, text: 'Iniciado' },
//     { key: 'FINISHED', value: 2, text: 'Finalizado' },
//   ],
// }

// export default {
//   rules: orderBy(data.rules, [item => item.text]),
// }

export default {
  INITIALIZED: 1,
  FINISHED: 2,
}
