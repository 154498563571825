<template>
  <div class="nj-app-bar">
    <!-- <v-icon size="32" class="mr-8"> mdi-menu </v-icon> -->
    <div class="app-bar-logo">
      <router-link to="/">
        <img :src="logo" alt="logo" />
      </router-link>
    </div>
    <div class="app-bar-title">
      {{ title }}
    </div>
    <div class="app-bar-user">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-list-item v-bind="attrs" v-on="on" class="px-0">
            <v-list-item-avatar v-if="user.avatar" size="28">
              <v-img :src="avatarSrc()"></v-img>
            </v-list-item-avatar>
            <v-avatar v-else>
              <v-icon size="32"> mdi-account-circle </v-icon>
            </v-avatar>
            <v-list-item-icon class="ml-0">
              <v-icon>mdi-menu-down</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </template>
        <slot name="submenu">
          <v-list>
            <v-list-item to="/preferences" key="app-bar-preferences">
              <v-list-item-title>Preferências</v-list-item-title>
            </v-list-item>
            <v-list-item @click="$emit('logout')" key="app-bar-logout">
              <v-list-item-title>Sair</v-list-item-title>
            </v-list-item>
          </v-list>
        </slot>
      </v-menu>
    </div>
  </div>
</template>
<script>
export default {
  name: 'nj-app-bar',
  props: {
    logo: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    user: {
      type: Object,
      default: () => {},
    },
  },

  methods: {
    avatarSrc() {
      return '#'
    },
  },
}
</script>
<style scoped>
.nj-app-bar {
  margin-bottom: 3rem;
  height: 40px;
  display: flex;
  align-items: center;
}

.app-bar-logo {
  /* flex: 0 0 140px; */
}

.app-bar-logo img {
  max-height: 45px;
  max-width: 100%;
}

.app-bar-title {
  font-size: 1.625rem;
  color: #384048;
  flex: 1;
}
/* .app-bar-user >>> .v-list-item:hover:before {
  border-radius: 1rem;
} */
</style>
