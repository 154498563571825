<template>
  <div class="sigin-layout fill-height">
    <v-row class="checkin fill-height" no-gutters>
      <v-col
        :cols="$vuetify.breakpoint.mobile ? 12 : 5"
        class="login-form fill-height"
      >
        <v-row align="center" justify="center" no-gutters>
          <v-col>
            <slot></slot>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        v-if="!$vuetify.breakpoint.mobile"
        class="custom-client-bg fill-height"
        v-html="organization.signin_html"
      ></v-col>
    </v-row>
  </div>
</template>
<script type="text/javascript">
import { mapState } from 'vuex'
export default {
  name: 'SignInLayout',
  computed: {
    ...mapState({
      organization: state => state.data.organization,
      termosPortal: state => state.data.organization.termos_portal,
    }),
  },
}
</script>
<style scoped lang="scss">
.custom-client-bg {
  // overflow-y: auto;
}
</style>
