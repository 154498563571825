<template>
  <div class="main-layout">
    <TermsModal ref="termsModal" />
    <v-app class="default-layout">
      <div style="display: flex; width: 100%">
        <v-main class="root-container">
          <AppBar
            :logo="organization.logo"
            :title="pageTitle"
            :user="user"
            @logout="$emit('logout')"
          >
            <template
              v-for="(index, name) in $scopedSlots"
              v-slot:[name]="data"
            >
              <slot :name="name" v-bind="data"></slot>
            </template>
          </AppBar>
          <slot></slot>
        </v-main>
      </div>
    </v-app>
  </div>
</template>
<script type="text/javascript">
import AppBar from './app_bar'
import TermsModal from '@/components/TermsModal.vue'
export default {
  name: 'MainLayout',
  components: { AppBar, TermsModal },

  mounted() {
    this.checkTerms()
  },

  methods: {
    checkTerms() {
      const requiresTerms =
        this.$store.state.data.organization.termos_portal.id != null

      if (requiresTerms) {
        const terms = this.$store.state.data.organization.termos_portal.id
        if (!this.$store.state.data.user.termos_uso_aceitos.includes(terms)) {
          this.$refs.termsModal.open()
        }
      }
    },
  },
  props: {
    pageTitle: {
      type: String,
      default: '',
    },
    menuItems: {
      type: Array,
      default: () => [],
    },
    menuDark: {
      type: Boolean,
      default: false,
    },
    menuColor: {
      type: String,
      default: null,
    },
    user: {
      type: Object,
      default: () => {},
    },
    organization: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
<style scoped>
.root-container {
  padding: 32px !important;
}
.main-layout > .v-application {
  background: #f6f7fb;
}
</style>
<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .root-container {
    padding: 25px 17px !important;
    margin-top: 50px;
  }
}
</style>
