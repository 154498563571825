import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router'
import createPersistedState from 'vuex-persistedstate'
import Api from '../resources/NajaSocialApi'
import schedule from './schedule.js'
import checkin from './checkin.js'

Vue.use(Vuex)

const getDefaultState = () => {
  return {
    user: {
      id: null,
      auth_token: null,
      short_token: null,
      core_token: null,
      role: '',
      name: null,
      username: null,
      cpf: null,
      email: null,
      naja_code: null,
      termos_uso_aceitos: [],
    },
    organization: {
      allows_checkin: null,
      allows_remote_calls: null,
      client_theme: null,
      enable_achedules_tab: null,
      enable_image_exams_tab: null,
      enable_lab_exams_tab: null,
      enable_remote_call_tab: null,
      external_report: null,
      url_resultado_consolidado: null,
      id: null,
      logo: null,
      name: null,
      show_pacs: null,
      signin_html: null,
      subdomain: null,
      slugs: [],
      portal_version: null,
      schedule_version: null,
      portal_home_tip: null,
      termos_portal: {
        id: null,
        texto: null,
        link_termos: null,
        nome: null,
        link_politica: null,
      },
    },
  }
}

const data = {
  state: getDefaultState(),
  mutations: {
    updateUser: (state, user) => {
      state.user = Object.assign(state.user, user)
    },

    updateTermosUsoAceitos: (state, termos_uso_aceitos) => {
      state.user.termos_uso_aceitos = Object.assign(
        state.user.termos_uso_aceitos,
        termos_uso_aceitos
      )
    },

    updateOrganization: (state, organization) => {
      state.organization = organization

      // const theme = themes[organization.client_theme] || {}
      // // Vuetify.framework.theme.themes.light = theme
      //
      // Vue.prototype.$vuetify.theme = theme
    },

    clearUser: state => {
      state.user = _.clone(getDefaultState().user)
    },
  },

  actions: {
    logout(context) {
      context.commit('clearUser')
      router.push({ name: 'login' })
    },

    async getUserInfo(context) {
      let result = await Api.me()
      context.commit('updateUser', result.data.user)
    },

    getOrganization(context) {
      return new Promise((resolve, reject) => {
        Api.getOrganizationInfo()
          .then(response => {
            context.commit('updateOrganization', response.data)
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },
  },

  getters: {
    role: state => {
      return state.user.role
    },

    token: state => {
      return state.user.auth_token
    },

    shortToken: state => {
      return state.user.short_token
    },

    isLogged: state => {
      return (
        state.user.auth_token !== undefined && state.user.auth_token !== null
      )
    },

    isOrganizationLoaded: state => {
      return state.organization.id != null
    },

    theme: state => {
      return state.organization.client_theme || 'default'
    },
  },
}

/* eslint-disable no-new */
const store = new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: ['data', 'checkin'],
    }),
  ],
  modules: {
    data: data,
    schedule: schedule,
    checkin: checkin,
  },
})

export default store
