<template>
  <v-dialog v-model="dialog" persistent max-width="600">
    <v-card>
      <v-card-title class="text-h5"> Política de Privacidade </v-card-title>
      <v-card-text>
        <p>Mudamos nossos termos de uso e política de privacidade.</p>
        <a
          class="blue--text text-decoration-underline"
          :href="termosPortal.link_termos"
          target="_blank"
        >
          Termos de uso
        </a>
        <br />
        <a
          class="blue--text text-decoration-underline"
          :href="termosPortal.link_politica"
          target="_blank"
        >
          Política de privacidade
        </a>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey darken-1" text @click.prevent="logout">
          Cancelar
        </v-btn>
        <v-btn color="green darken-1" text @click.prevent="acceptTerms">
          Sim, aceito.
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import Api from '@/resources/NajaSocialApi'
import { mapState } from 'vuex'

export default {
  name: 'TermsModal',

  computed: {
    ...mapState({
      termosPortal: state => state.data.organization.termos_portal,
    }),

    theme() {
      return this.$store.getters.theme
    },
  },

  data() {
    return {
      dialog: false,
      loading: false,
    }
  },

  methods: {
    open() {
      this.dialog = true
    },

    logout() {
      this.dialog = false
      this.$store.dispatch('logout')
    },

    acceptTerms() {
      this.loading = true
      const data = {
        termo:
          this.$store.state.data.organization.configuracao_termos_uso.portal,
      }

      Api.acceptTerms(data)
        .then(response => {
          this.$store.commit(
            'updateTermosUsoAceitos',
            response.data.termos_uso_aceitos
          )
          this.dialog = false
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
