<template>
  <v-text-field
    :label="label"
    :value="formattedValue"
    @input="updateModel"
    :disabled="disabled"
    filled
    outlined
    dense
    background-color="#fff"
  ></v-text-field>
</template>
<script>
const maskValue = value => {
  if (value !== null && value !== '' && value !== undefined && !isNaN(value)) {
    return (value / 100).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    })
  }

  return null
}
export default {
  name: 'MoneyInput',
  props: {
    value: {
      type: [String, Number],
      default: 0,
    },
    label: {
      type: String,
      default: 'Money',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const initValue = maskValue(this.value * 100)
    return {
      formattedValue: initValue,
    }
  },

  watch: {
    value(newValue) {
      const maskedValue = maskValue(newValue * 100)
      this.formattedValue = maskedValue
    },
  },

  methods: {
    updateModel(inputValue) {
      const rawValue = inputValue.replace(/[^\d]/g, '')
      const numericValue = parseFloat(rawValue) / 100

      this.$emit('input', numericValue)

      const maskedValue = maskValue(numericValue * 100)
      setTimeout(() => {
        this.formattedValue = maskedValue
      }, 100)
    },
  },
}
</script>
