<template>
  <v-app class="schedule-layout">
    <AppBar
      :logo="organization.logo"
      :title="pageTitle"
      :user="user"
      @logout="$emit('logout')"
      class="primary--text"
    >
      <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
        <slot :name="name" v-bind="data"></slot>
      </template>
    </AppBar>

    <v-main>
      <v-container class="py-8 px-4">
        <slot></slot>
      </v-container>
    </v-main>
  </v-app>
  <!-- <div class="schedule-layout">
    <v-app class="default-layout">
      <div style="display: flex; width: 100%">
        <v-main class="root-container">
          <AppBar
            :logo="organization.logo"
            :title="pageTitle"
            :user="user"
            @logout="$emit('logout')"
            class="primary--text"
          >
            <template
              v-for="(index, name) in $scopedSlots"
              v-slot:[name]="data"
            >
              <slot :name="name" v-bind="data"></slot>
            </template>
          </AppBar>
          <slot></slot>
        </v-main>
      </div>
    </v-app>
  </div> -->
</template>
<script type="text/javascript">
import { mapState } from 'vuex'
import AppBar from './app_bar'
import Breadcrumbs from './breadcrumbs'
export default {
  name: 'ScheduleLayout',
  components: { AppBar, Breadcrumbs },
  props: {
    pageTitle: {
      type: String,
      default: '',
    },
    menuItems: {
      type: Array,
      default: () => [],
    },
    menuDark: {
      type: Boolean,
      default: false,
    },
    menuColor: {
      type: String,
      default: null,
    },
    user: {
      type: Object,
      default: () => {},
    },
    organization: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapState({
      step: state => state.schedule.step,
    }),

    stepTitle() {
      return {
        1: 'Selecione o procedimento e o convênio',
        2: 'Selecione o local e o horário',
        3: 'Insira seus dados',
        4: 'Revise os dados e confirme seu agendamento',
      }[this.step]
    },
  },
}
</script>
<style>
.schedule-layout {
  --box-bg-color: #f4f4f8;
  --text-color: #262529;
  height: 100%;
  background: var(--box-bg-color) !important;
}
</style>

<style scoped>
.schedule-layout >>> .v-application {
  /* background: white; */
  line-height: 1.371rem;
  color: #262529;
  background: var(--box-bg-color);
}

.schedule-layout >>> .v-main {
  background-color: white;
  border-radius: 1.5rem 1.5rem 0px 0px;
}

.schedule-layout >>> .v-application .text-h6 {
  line-height: 1.371rem;
}
</style>
<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';
@media #{map-get($display-breakpoints, 'lg-and-up')} {
  .schedule-layout .container {
    max-width: 1440px;
  }
  // .root-container {
  //   padding: 25px 17px !important;
  //   margin-top: 50px;
  // }
}
</style>
