import $ from 'jquery'
import 'notifyjs-browser'

export const toastOptions = {
  autoHide: true,
  autoHideDelay: 3500,
  clickToHide: true,
}

export default {
  show: function (msg, type) {
    toastOptions.className = type + ' closable'
    $.notify(msg, toastOptions)
  },
}
