<template>
  <div class="nj-app-bar">
    <div class="app-bar-logo">
      <img :src="logo" alt="logo" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'CheckinLayoutAppBar',
  props: {
    logo: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },

  methods: {
    avatarSrc() {
      return '#'
    },
  },
}
</script>
<style scoped>
.nj-app-bar {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.app-bar-logo {
  text-align: center;
}

.app-bar-logo img {
  max-height: 45px;
  max-width: 100%;
}
</style>
