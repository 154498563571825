import axios from 'axios'
import store from '../store'
import $ from 'jquery'

function getSubdomain() {
  return window.location.host.split('.')[0]
}

console.log(
  'appointment_schedule_advanced_search',
  store.state.schedule.options.appointment_schedule_advanced_search
)

console.log(
  'exam_schedule_advanced_search',
  store.state.schedule.options.exam_schedule_advanced_search
)

export const axiosInstance = axios.create({
  baseURL: `//${getSubdomain()}.${
    process.env.VUE_APP_NAJA_API_HOST
  }/naja-api/v1`,
  transformRequest: [
    function (data, headers) {
      headers['Naja-Api-Key'] = process.env.VUE_APP_NAJA_API_ACCESS_KEY
      if (store.getters.token != null) {
        headers['Authorization'] = store.getters.token
      }

      return JSON.stringify(data)
    },
  ],
  headers: { 'Content-Type': 'application/json' },
})

axiosInstance.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error.response.status === 401) {
      if (store.getters.role === 'checkin') {
        store.dispatch('checkin/logout')
      } else {
        store.dispatch('logout')
      }
    }

    // if (error.response.status === 500) {
    //   Toast.show("Error fatal", "error");
    // }

    return Promise.reject(error)
  }
)

export const endpoints = {
  authenticate: '/authenticate',
  coreAuthenticate: '/core-authenticate',
  appAuthenticate: '/app-authenticate',
  organizations: '/organizations',
  organizationInfo: '/organizations/info',
  me: '/me',
  exams: '/exams',
  imageExams: '/exams/images',
  labExams: '/exams/labs',
  subgroups: '/exams/subgroups',
  preserviceSchedules: '/preservices/schedules',
  scheduleOptions: '/organizations/schedule-options',
  preservices: '/preservices',
  checkins: '/checkins',
  ufs: '/ufs',
  cities: '/cities',
  businessCard: '/card/business_card',
  virtualSchedule: '/card/virtual_schedule',
  medicalReport: '/medical_reports',
  onlineSchedules: '/online_schedules',
  pacs: '/medical_reports/pacs',
  medicalReportCombined: '/medical_reports/combined',
  groups: '/grupos',
  products: '/products',
  covenants: '/covenants',
  schedules: '/schedules',
  patientSchedules: '/patient_schedules',
  branches: '/branches',
  patients: '/patients',
  orders: '/orders',
  userTasks: '/user_tasks',
  resetPasswordInstructions: '/reset-password-instructions',
  resetPassword: '/reset-password',
  confirmSchedule: '/confirm_schedule',
  attachments: '/attachments',
  heatmaps: '/heatmaps',
  acceptTerms: '/accept-terms',
  termosUso: '/termos_uso',
  settings: '/me/settings',
  patientSettings: '/me/patient-settings',
  updateSettings: '/me/update-settings',
}

export default {
  baseURL: axiosInstance.defaults.baseURL,
  najaBaseURL: axiosInstance.defaults.baseURL.replace('/naja-api/v1', ''),
  core: {
    questionnaires: {
      fetch: id => axiosInstance.get(`core/questionnaires/${id}`),
      save: (id, payload) =>
        axiosInstance.put(`core/questionnaires/${id}`, payload),
      saveStatus: (id, payload) =>
        axiosInstance.put(`core/questionnaires/${id}/status`, payload),
    },
    quotes: {
      fetch: (id, query) =>
        axiosInstance.get(`core/quotes/${id}?${$.param(query)}`),
      savePrices: (id, payload) =>
        axiosInstance.post(`core/quotes/${id}/prices`, payload),
    },
    preparations: {
      fetch: id => axiosInstance.get(`core/preparations/${id}`),
    },
    schedules: {
      questionnaires: id =>
        axiosInstance.get(`core/schedules/${id}/questionnaires`),
    },
  },
  accessToken: process.env.VUE_APP_NAJA_API_ACCESS_KEY,
  authenticate: credentials =>
    axiosInstance.post(endpoints.authenticate, credentials),
  coreAuthenticate: () => axiosInstance.post(endpoints.coreAuthenticate),
  appAuthenticate: credentials =>
    axiosInstance.post(endpoints.appAuthenticate, credentials),
  getOrganizationInfo: () => axiosInstance.get(endpoints.organizationInfo),
  userInfo: token =>
    axiosInstance.get(endpoints.me, { headers: { Authorization: token } }),
  me: () => axiosInstance.get(endpoints.me),
  exams: () => axiosInstance.get(endpoints.exams),
  imageExams: params =>
    axiosInstance.get(`${endpoints.imageExams}?${$.param(params)}`),
  labExams: params =>
    axiosInstance.get(`${endpoints.labExams}?${$.param(params)}`),
  examAttachments: params =>
    axiosInstance.get(`${endpoints.exams}/attachments?${$.param(params)}`),
  getAttachment: id => axiosInstance.get(`${endpoints.attachments}/${id}`),
  preserviceSchedules: params =>
    axiosInstance.get(`${endpoints.preserviceSchedules}?${$.param(params)}`),
  createPreservice: data => axiosInstance.post(endpoints.preservices, data),
  // createCheckin: data => axiosInstance.post(endpoints.checkins, data),
  // updateCheckin: (id, data) =>
  //   axiosInstance.put(`${endpoints.checkins}/${id}`, data),
  checkin: {
    search: params =>
      axiosInstance.get(`${endpoints.checkins}?${$.param(params)}`),
    save: (id, data) => {
      const method = id ? 'put' : 'post'
      const url = id ? `${endpoints.checkins}/${id}` : endpoints.checkins
      const options = { method: method, url: url, data: data }
      return axiosInstance(options)
    },
  },
  // uploadCheckinFiles: (id, formData) => axiosInstance.post(`${endpoints.checkin}/${id}/upload`, formData, {headers: {'Content-Type': 'multipart/form-data'}}),
  ufs: () => axiosInstance.get(endpoints.ufs),
  cities: params => axiosInstance.get(`${endpoints.cities}?${$.param(params)}`),
  settings: () => axiosInstance.get(endpoints.settings),
  patientSettings: padIsn =>
    axiosInstance.get(`${endpoints.patientSettings}?pac_isn=${padIsn}`),
  updateSettings: data => axiosInstance.put(endpoints.updateSettings, data),
  pacs: params =>
    axiosInstance.get(`${endpoints.pacs}?${$.param(params)}`, {
      redirect: 'manual',
    }),
  businessCard: token =>
    axiosInstance.get(`${endpoints.businessCard}?token=${token}`),
  virtualSchedule: token =>
    axiosInstance.get(`${endpoints.virtualSchedule}?token=${token}`),
  orders: {
    public: id => axiosInstance.get(`${endpoints.orders}/${id}/public`),
    fetch: id => axiosInstance.get(`${endpoints.orders}/${id}`),
  },
  onlineSchedules: {
    search: params =>
      axiosInstance.get(`${endpoints.onlineSchedules}?${$.param(params)}`),
    fetch: id => axiosInstance.get(`${endpoints.onlineSchedules}/${id}`),
    destroy: id => axiosInstance.delete(`${endpoints.onlineSchedules}/${id}`),
    save: (id, data) => {
      const method = id ? 'put' : 'post'
      const url = id
        ? `${endpoints.onlineSchedules}/${id}`
        : endpoints.onlineSchedules
      const options = { method: method, url: url, data: data }
      return axiosInstance(options)
    },
  },
  medicalReportURL: id =>
    `${axiosInstance.defaults.baseURL}${endpoints.medicalReport}/${id}/pdf?st=${store.getters.shortToken}`,
  publicMedicalReport: (id, query) =>
    axiosInstance.get(
      `${axiosInstance.defaults.baseURL}${
        endpoints.medicalReport
      }/${id}/public?${$.param(query)}`
    ),
  medicalReportPDF: (
    id,
    pacIsn = null,
    publicReport = false,
    viewer = 'portal'
  ) => {
    let url = publicReport
      ? `${endpoints.medicalReport}/${id}/public_pdf?viewer=${viewer}`
      : `${endpoints.medicalReport}/${id}/pdf?viewer=${viewer}`

    if (pacIsn) {
      url = `${url}&pac_isn=${pacIsn}`
    }

    return axiosInstance.get(url, {
      headers: { Accept: 'application/pdf' },
      responseType: 'arraybuffer',
    })
  },
  medicalReportCombinedPDF: ids =>
    axiosInstance.get(`${endpoints.medicalReportCombined}?ids=${ids}`, {
      headers: { Accept: 'application/pdf' },
      responseType: 'blob',
    }),
  medicalReportConsolidated: payload => {
    const options = {
      method: 'get',
      url: `${endpoints.medicalReport}/consolidated`,
      params: payload,
    }
    return axiosInstance(options)
    // axiosInstance.get(`${endpoints.medicalReport}/consolidated`, headers: { Accept: 'application/pdf' }, payload),
  },

  medicalReportOrigin: id =>
    axiosInstance.get(`${endpoints.medicalReport}/${id}/origin`),
  subgroups: () => axiosInstance.get(endpoints.subgroups),
  groups: () => axiosInstance.get(endpoints.groups),
  termosUso: id => axiosInstance.get(`${endpoints.termosUso}/${id}`),
  products: subgroups =>
    axiosInstance.get(`${endpoints.products}?subgroups=${subgroups}`),
  // covenants: () => axiosInstance.get(`${endpoints.covenants}?con_ativo=true&cve_icagendaonline=true`),
  covenants: params => axiosInstance.get(`${endpoints.covenants}?${params}`),
  scheduleOptions: () => axiosInstance.get(endpoints.scheduleOptions),
  schedules: params => axiosInstance.get(`${endpoints.schedules}?${params}`),
  createHeatmap: data => axiosInstance.post(endpoints.heatmaps, data),
  patientSchedules: params =>
    axiosInstance.get(`${endpoints.patientSchedules}?${$.param(params)}`),
  createSchedule: (schedule, recaptchaToken) =>
    axiosInstance.post(endpoints.schedules, schedule, {
      headers: { RecaptchaToken: recaptchaToken },
    }),
  branches: params => axiosInstance.get(`${endpoints.branches}?${params}`),
  patient: id => axiosInstance.get(`${endpoints.patients}/${id}`),
  decode: token => axiosInstance.get(`/short_tokens/decode?token=${token}`),
  patients: params =>
    axiosInstance.get(`${endpoints.patients}?${$.param(params)}`),
  createUserTask: (data, recaptchaToken) =>
    axiosInstance.post(endpoints.userTasks, data, {
      headers: { RecaptchaToken: recaptchaToken },
    }),
  scheduleResources: params =>
    axiosInstance.get(`${endpoints.schedules}/resources?${params}`),
  resetPasswordInstructions: data =>
    axiosInstance.post(endpoints.resetPasswordInstructions, data),
  resetPassword: data => axiosInstance.post(endpoints.resetPassword, data),
  confirmSchedule: data => axiosInstance.post(endpoints.confirmSchedule, data),
  acceptTerms: data => axiosInstance.post(endpoints.acceptTerms, data),
}
